<template>
  <transition name="fade" mode="out-in">
    <div v-if="content" class="dp-capsule-detail">
      <div class="dp-capsule-detail-content">
        <div ref="detailContentInner" class="dp-capsule-detail-content__inner">
          <button
            type="button"
            class="dp-capsule-detail-close"
            v-ripple="$utilities.getRippleOptions('light')"
            @click="onClickClose">
            <Icon name="dpClose" color="dpWhite01" :width="18" :height="18" />
          </button>

          <p v-if="content.name" class="dp-capsule-detail-title">
            <Icon name="capsuleR" color="dpWhite01" :width="20" :height="20" />
            <span>{{ content.name }}</span>
          </p>

          <p v-if="content.description" class="dp-capsule-detail-description">
            {{ content.description }}
          </p>

          <div class="dp-capsule-detail-tastes">
            <div
              v-for="TASTE in TASTES"
              :key="TASTE.name"
              class="dp-capsule-detail-taste">
              <span class="dp-capsule-detail-taste__label">{{
                TASTE.label
              }}</span>
              <div class="dp-capsule-detail-taste__meter">
                <component
                  :is="getTasteMeter(TASTE.name) >= item ? 'div' : 'span'"
                  v-for="item in METER_LENGTH"
                  :key="item"
                  :class="[
                    'dp-capsule-detail-taste__meter-item',
                    getTasteMeter(TASTE.name) >= item
                      ? 'dp-capsule-detail-taste__meter-item--active'
                      : false
                  ]" />
              </div>
            </div>
          </div>

          <DpButton
            style-type="secondary"
            size="lg"
            :to="`/drip-pod/pro-recipe/capsule/${content.id}`"
            :is-white-border="true"
            fluid>
            {{ WORDS.DRIP_POD_CAPSULE.SELECT_CAPSULE }}
          </DpButton>

          <DpTextButton
            v-if="selectedCapsuleEcUrl"
            :href="selectedCapsuleEcUrl"
            align="right"
            color="dpWhite01"
            fluid>
            <template v-slot:iconAppend>
              <Icon name="dpArrowRightB" color="dpWhite01" />
            </template>
            {{ WORDS.DRIP_POD_CAPSULE.STORE_LINK_CAPSULE }}
          </DpTextButton>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { WORDS } from '@/constants/words';
import {
  defineComponent,
  ref,
  inject,
  computed,
  watch
} from '@vue/composition-api';

export default defineComponent({
  components: {
    DpButton: () => import('@/components/dripPod/core/Button.vue'),
    DpTextButton: () => import('@/components/dripPod/core/TextButton.vue')
  },

  setup: (props, context) => {
    const deactivate = inject('deactivateSelectedCapsuleDetail');
    const content = inject('selectedCapsule');
    const TASTES = [
      {
        name: 'bitter',
        label: '苦味'
      },
      {
        name: 'acidity',
        label: '酸味'
      },
      {
        name: 'rich',
        label: 'コク'
      }
    ];
    const isActive = ref(false);
    const detailContentInner = ref(undefined);
    const METER_LENGTH = 5;
    /* eslint-disable indent */
    const selectedCapsuleEcUrl = computed(() =>
      content.value?.link_url
        ? context.root.$customUrlScheme.page(
            'ec',
            `url=${content.value.link_url}`
          )
        : false
    );
    /* eslint-enable */

    const getTasteMeter = (name) => content.value.taste_parameter[name];

    const onClickClose = async () => {
      isActive.value = false;
      deactivate();
    };

    watch(content, () => {
      if (detailContentInner.value) detailContentInner.value.scrollTo(0, 0);
    });

    return {
      WORDS,
      TASTES,
      METER_LENGTH,
      content,
      detailContentInner,
      onClickClose,
      selectedCapsuleEcUrl,
      getTasteMeter
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-capsule-detail {
  z-index: 100;
  position: relative;
}

.dp-capsule-detail-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px 16px;
}

.dp-capsule-detail-content {
  position: fixed;
  bottom: 0;
  padding: 36px 0 0;
  background-color: variables.$dpBlack02;
  color: variables.$dpWhite01;
  width: 100%;

  &__inner {
    padding: 0 16px 24px;
    max-height: 50vh;
    overflow-y: scroll;
  }
}

.dp-capsule-detail-title {
  display: grid;
  grid-template-columns: 22px 1fr;
  place-items: start;
  column-gap: 6px;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: bold;
  white-space: pre-wrap;

  > :first-child {
    margin-top: 1px;
  }
}

.dp-capsule-detail-description {
  margin-bottom: 24px;
  line-height: 1.57;
  white-space: pre-wrap;
}

.dp-capsule-detail-tastes {
  margin-bottom: 32px;
}

.dp-capsule-detail-taste {
  display: grid;
  grid-template-columns: 2em 1fr;
  place-items: center start;
  column-gap: 16px;
  margin-bottom: 4px;
  line-height: 1.3;

  &:last-child {
    margin-bottom: 0;
  }

  &__label {
    font-weight: bold;
  }

  &__meter {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    place-items: center;
    gap: 0 4px;
    width: 100%;
    height: 12px;
  }
}

.dp-capsule-detail-taste__meter-item {
  position: relative;
  display: block;
  background-color: variables.$dpGrey66;
  width: 100%;
  height: 2px;

  &.dp-capsule-detail-taste__meter-item--active {
    background-color: variables.$white01;
    height: 2px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
